import { FC, useRef } from 'react';

import { useCycle, Variants } from 'framer-motion';

import { StlyedNav, StyledBackground } from './MobileMenu.styles';
import MobileMenuNavigation from './MobileMenuNavigation/MobileMenuNavigation';
import MobileMenuToggle from './MobileMenuToogle/MobileMenuToogle';

interface Dimensions {
  height: number;
  width: number;
}

const sidebar: Variants = {
  open: (dimensions: Dimensions) => {
    return {
      clipPath: `circle(${dimensions.height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2
      }
    };
  },
  closed: (dimensions: Dimensions) => {
    return {
      clipPath: `circle(30px at ${dimensions.width - 40}px 41px)`,
      transition: {
        delay: 0.5,
        type: 'spring',
        stiffness: 400,
        damping: 40
      }
    };
  }
};

const MobileMenu: FC = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef<HTMLElement>(null);

  const handleToggleClick = (): void => {
    toggleOpen();
    // Check for isOpen equals false because isOpen not yet updated here
    if (!isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  return (
    <StlyedNav initial={false} animate={isOpen ? 'open' : 'closed'} ref={containerRef}>
      <StyledBackground
        isOpen={isOpen}
        custom={{
          height: window.innerHeight,
          width: window.innerWidth
        }}
        variants={sidebar}
      />
      <MobileMenuNavigation onToggleClick={handleToggleClick} />
      <MobileMenuToggle onToggle={() => handleToggleClick()} />
    </StlyedNav>
  );
};

export default MobileMenu;
