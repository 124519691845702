import { FC } from 'react';

import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';

import { buildFullBackgroundStyles, StyledFullBackgroundProps, StyledFullOverlay } from './FullBackground.styles';

interface FullBackgroundProps extends StyledFullBackgroundProps {
  fluid: FluidObject;
}

const FullBackground: FC<FullBackgroundProps> = ({ children, fullViewPort = false, fluid }) => {
  return (
    <BackgroundImage
      Tag="div"
      css={buildFullBackgroundStyles({ fullViewPort })}
      fluid={fluid}
      title="Tomasz Dyła"
      role="img"
      alt="background image"
      aria-label="Tomasz Dyła"
      preserveStackingContext
      fullViewPort={fullViewPort}
    >
      <StyledFullOverlay />
      {children}
    </BackgroundImage>
  );
};

export default FullBackground;
