import styled from '@emotion/styled';

export const StyledTickerImgsContainer = styled.div`
  display: flex;
`;

export const StyledTickerContainer = styled.div`
  position: relative;
`;

export const StyledCompanyImgContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  max-width: 300px;
  max-height: 90px;
`;
