import { FC } from 'react';

import { Col, Row, Layout, Space } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Fade } from 'react-awesome-reveal';

import { Section } from '../../models/Section';
import BodyText from '../BodyText/BodyText';
import Divider from '../Divider/Divider';
import PageContainer from '../PageContainer/PageContainer';
import { StyledLTopMargin, StyledNotMobileRightPadding, StyledXlTopBottomMargin } from '../SharedStyles';
import { StyledSignImgContainer, StyledTitle } from './LadiesGentlemen.styles';

const LadiesGentlemen: FC = () => {
  const { podpis } = useStaticQuery(
    graphql`
      query {
        podpis: file(relativePath: { eq: "podpis.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 460) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  const panstwoTitle = 'Szanowni Państwo,';
  const panstwoDesc =
    'Specjalizuję się w doradztwie oraz pośrednictwie w najmie powierzchni biurowych. W trakcie mojej pracy zawodowej brałem udział w kilkuset transakcjach najmu biur, pomagając w wynajęciu kilkudziesięciu tysięcy metrów kwadratowych powierzchni. Pracuję zarówno z międzynarodowymi korporacjami, jak i z polskimi podmiotami. Bardzo chętnie podzielę się z Państwem swoją wiedzą i doświadczeniem, pomagając w znalezieniu nowego biura i przeprowadzeniu całego procesu najmu lub renegocjacjach dotychczasowej umowy. Zapraszam do kontaktu!';

  return (
    <Layout.Content id={Section.LADIES_GENTLEMEN}>
      <PageContainer>
        <StyledXlTopBottomMargin>
          <Row>
            <StyledLTopMargin>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} xs={{ span: 24 }}>
                <Space direction="vertical">
                  <Fade>
                    <StyledNotMobileRightPadding>
                      <StyledTitle>{panstwoTitle}</StyledTitle>
                      <StyledLTopMargin>
                        <BodyText> {panstwoDesc}</BodyText>
                      </StyledLTopMargin>
                      <Divider variant="medium" />
                      <StyledSignImgContainer>
                        <Img fluid={podpis.childImageSharp.fluid} />
                      </StyledSignImgContainer>
                    </StyledNotMobileRightPadding>
                  </Fade>
                </Space>
              </Col>
            </StyledLTopMargin>
          </Row>
        </StyledXlTopBottomMargin>
      </PageContainer>
    </Layout.Content>
  );
};

export default LadiesGentlemen;
