import styled from '@emotion/styled';
import { Layout } from 'antd';

import { sectionStyle } from '../../globalStyles';

export const StyledHeader = styled(Layout.Header)`
  &&& {
    z-index: 2;
    ${sectionStyle}
    background: transparent;
  }
`;
