import { Section } from '../models/Section';

export interface MenuItem {
  text: string;
  sectionId: Section;
}

export const menuItems: MenuItem[] = [
  {
    text: 'O mnie',
    sectionId: Section.ABOUT_ME
  },
  {
    text: 'Korzyści',
    sectionId: Section.BENEFITS
  },
  {
    text: 'Wsparcie',
    sectionId: Section.OFFERS
  },
  {
    text: 'Doświadczenie',
    sectionId: Section.EXPERIENCE
  },
  {
    text: 'Kontakt',
    sectionId: Section.CONTACT_ME
  }
];
