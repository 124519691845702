import { AiOutlineCalculator } from '@react-icons/all-files/ai/AiOutlineCalculator';
import { BsBuilding } from '@react-icons/all-files/bs/BsBuilding';
import { GiHouseKeys } from '@react-icons/all-files/gi/GiHouseKeys';
import { HiOutlinePencil } from '@react-icons/all-files/hi/HiOutlinePencil';
import { IconType } from '@react-icons/all-files/lib/esm';
import { MdLocationOn } from '@react-icons/all-files/md/MdLocationOn';
import { MdNote } from '@react-icons/all-files/md/MdNote';

export interface Offer {
  title: string;
  description: string;
  Icon: IconType;
}

export const offers: Offer[] = [
  {
    title: 'określenie potrzeb najemcy',
    description:
      'omówienie sytuacji rynkowej i prognoz; analiza zapotrzebowania najemcy; ustalenie strategii działania',
    Icon: HiOutlinePencil
  },
  {
    title: 'wybór i prezentacja ofert',
    description: 'przedstawienie ofert; organizacja prezentacji i wizyt w budynkach',
    Icon: MdLocationOn
  },
  {
    title: 'analizy finansowe i jakościowe wybranych ofert',
    description: 'przygotowanie zapytań ofertowych (RFP); analiza ofert; przejrzyste porównanie ofert',
    Icon: BsBuilding
  },
  {
    title: 'negocjacje',
    description: 'ustalenie strategii negocjacyjnej; pomoc w negocjacjach',
    Icon: AiOutlineCalculator
  },
  {
    title: 'podpisanie umowy',
    description: 'monitoring, egzekucja i weryfikacja przyjętych założeń i celów',
    Icon: GiHouseKeys
  },
  {
    title: 'usługi dodatkowe',
    description:
      'pomoc w projektowaniu; omówienie aktualnych trendów biurowych; dobór mebli biurowych oraz innych elementów biura.',
    Icon: MdNote
  }
];
