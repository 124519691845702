import { FC } from 'react';

import { motion, Variants } from 'framer-motion';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { MenuItem } from '../../../data/menuItems';
import { Section } from '../../../models/Section';
import { StyledTextPlaceholder } from './MobileMenuItem.styles';

const variants: Variants = {
  open: {
    y: 0,
    opacity: 1,
    display: 'flex',
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    },
    transitionEnd: {
      zIndex: -10,
      display: 'none'
    }
  }
};

interface MobileMenuItemProps extends MenuItem {
  onToggleClick: () => void;
}

const MobileMenuItem: FC<MobileMenuItemProps> = ({ text, onToggleClick, ...props }) => {
  const handleMobileMenuItemClick = (sectionId: Section): void => {
    onToggleClick();
    setTimeout(() => {
      scrollTo(`#${sectionId}`);
    }, 700);
  };

  return (
    <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <StyledTextPlaceholder onClick={() => handleMobileMenuItemClick(props.sectionId)}>{text}</StyledTextPlaceholder>
    </motion.li>
  );
};

export default MobileMenuItem;
