import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

import { colors } from '../../theme';

const buildSharedStyles = (isOpen: boolean): SerializedStyles => css`
  position: ${isOpen ? 'fixed' : 'absolute'};
  right: 0;
  bottom: 0;
  width: 100%;
`;

interface StyledBackgroundProps {
  isOpen: boolean;
}

export const StyledBackground = styled(motion.div)<StyledBackgroundProps>`
  ${({ isOpen }) => buildSharedStyles(isOpen)}
  top: -100px;
  background: ${colors.primary.white};
`;

export const StlyedNav = styled(motion.nav)`
  top: 0;
  ${({ animate }) => buildSharedStyles(animate === 'open')};
  position: unset;
  display: flex;
  justify-content: center;
`;
