import { FC } from 'react';

import { GoPrimitiveDot } from '@react-icons/all-files/go/GoPrimitiveDot';
import { Col, Row, Layout, Space } from 'antd';
import { Fade } from 'react-awesome-reveal';

import { Section } from '../../models/Section';
import BodyText from '../BodyText/BodyText';
import PageContainer from '../PageContainer/PageContainer';
import { StyledLTopMargin, StyledNotMobileLeftPadding, StyledXlTopBottomMargin } from '../SharedStyles';
import TitleWithLine from '../TitleWithLine/TitleWithLine';

const Benefits: FC = () => {
  const panstwoTitle = 'Korzyści dla klienta'.toUpperCase();
  const benefits = [
    'moja wiedza na temat nieruchomości biurowych pozwoli Państwu podjąć optymalne decyzje w zakresie najmu,',
    'moje doświadczenie na rynku jest gwarancją, że proces najmu (lub renegocjacji) powierzchni biurowej przebiegnie bezpiecznie i bez zakłóceń,',
    'zdobyte i sprawdzone przeze mnie kontakty biznesowe, sprawiają, że świadczę dla Państwa kompleksową usługę łączącą wszystkie aspekty najmu biura (finansowe, prawne, architektoniczne), co w znacznym stopniu oszczędzi Państwa czas i zapewni bezpieczeństwo transakcji.'
  ];

  return (
    <Layout.Content id={Section.BENEFITS}>
      <PageContainer>
        <StyledXlTopBottomMargin>
          <Row>
            <StyledLTopMargin>
              <Col xl={{ offset: 12, span: 12 }} lg={{ offset: 12, span: 12 }} xs={{ span: 24 }}>
                <Fade>
                  <StyledNotMobileLeftPadding>
                    <TitleWithLine>{panstwoTitle}</TitleWithLine>
                    <StyledLTopMargin>
                      <Space direction="vertical">
                        {benefits.map((text) => (
                          <BodyText iconProps={{ size: 20 }} Icon={GoPrimitiveDot} key={text}>
                            {text}
                          </BodyText>
                        ))}
                      </Space>
                    </StyledLTopMargin>
                  </StyledNotMobileLeftPadding>
                </Fade>
              </Col>
            </StyledLTopMargin>
          </Row>
        </StyledXlTopBottomMargin>
      </PageContainer>
    </Layout.Content>
  );
};

export default Benefits;
