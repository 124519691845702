import styled from '@emotion/styled';

import { colors } from '../../theme';

export const SyledOfferItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 324px;
  align-items: center;
  text-align: center;
  padding: 20px;
  transition: background-color 0.5s ease-in-out;

  border-radius: 25px;
  :hover {
    background-color: ${colors.primary.black};
  }
`;

export const SyledOfferItemTitle = styled.h1`
  margin-bottom: 10px;
  color: ${colors.primary.white};
  font-size: 24px;
  font-weight: bold;
`;

export const StyledOfferItemIconContainer = styled.div`
  svg {
    font-size: 40px;
    color: ${colors.primary.orange};
  }
`;
