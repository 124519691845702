import styled from '@emotion/styled';

import { colors } from '../../theme';

export const StyledSignImgContainer = styled.div`
  max-width: 460px;
  margin-right: 0;
  margin-left: auto;
`;

export const StyledTitle = styled.h1`
  color: ${colors.primary.orange};
  font-size: 35px;
  font-weight: bolder;
`;
