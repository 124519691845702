import styled from '@emotion/styled';

import { colors } from '../../../theme';

export const StyledTextPlaceholder = styled.div`
  width: 200px;
  height: 20px;
  flex: 1;
  color: ${colors.primary.orange};
`;
