import React, { FC } from 'react';

import { MdLabel } from '@react-icons/all-files/md/MdLabel';
import { Col, Layout, Row, Space } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Slide, Zoom } from 'react-awesome-reveal';

import useWindowSize from '../../hooks/useWindowSize';
import { Section } from '../../models/Section';
import BodyText from '../BodyText/BodyText';
import Button from '../Button/Button';
import Divider from '../Divider/Divider';
import PageContainer from '../PageContainer/PageContainer';
import { StyledLBottomMargin, StyledOrangeColor, StyledXlTopMargin } from '../SharedStyles';
import TitleWithLine from '../TitleWithLine/TitleWithLine';
import { StyledHugeTitle, StyledTitle, StyledTomaszImg } from './AboutMe.styles';

const AboutMe: FC = () => {
  const windowSize = useWindowSize();
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "Tomasz.png" }) {
          childImageSharp {
            fluid(maxWidth: 460, maxHeight: 720, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const topSectionTitle = 'Kompleksowe doradztwo w zakresie najmu powierzchni biurowych'.toUpperCase();
  const aboutMeTitle = 'O Mnie'.toUpperCase();
  const aboutMe = [
    'posiadam 15 lat doświadczenia na rynku nieruchomości komercyjnych,',
    'doradzam międzynarodowym i lokalnym firmom,',
    'początkowo pracowałem dla DTZ (obecnie Cushman&Wakefield), a następnie założyłem i prowadzę od ponad 12 lat Vertigo Property Group,',
    'jestem absolwentem Szkoły Głównej Handlowej oraz dr. nauk ekonomicznych tejże Uczelni.'
  ];
  const buttonText = 'Skontaktuj się';

  const renderTitle = (): JSX.Element => (
    <StyledHugeTitle level={1}>
      <StyledOrangeColor>Dr</StyledOrangeColor> Tomasz <br /> Dyła
    </StyledHugeTitle>
  );

  return (
    <>
      <Layout.Content id={Section.ABOUT_ME}>
        <PageContainer>
          <Zoom>
            <StyledTitle>{topSectionTitle}</StyledTitle>
          </Zoom>
          <Row align="middle">
            <Col xl={{ span: 9 }} lg={{ span: 7 }} xs={{ span: 24 }}>
              <Slide direction="left">{renderTitle()}</Slide>
            </Col>
            <Col xl={{ span: 0 }} lg={{ span: 0 }} xs={{ span: 24 }}>
              <StyledTomaszImg>
                <Img style={{ position: 'initial' }} fluid={desktop.childImageSharp.fluid} />
              </StyledTomaszImg>
            </Col>
            <Col xl={{ span: 7, offset: 8 }} lg={{ span: 6, offset: 10 }} xs={{ span: 24, offset: 0 }}>
              <Slide direction="right">
                <StyledXlTopMargin>
                  <TitleWithLine>{aboutMeTitle}</TitleWithLine>
                  <Space direction="vertical">
                    {aboutMe.map((text) => (
                      <BodyText Icon={MdLabel} key={text}>
                        {text}
                      </BodyText>
                    ))}
                    <Divider variant="medium" />
                    <Button aria-label={buttonText} onClick={() => scrollTo(`#${Section.CONTACT_ME}`)}>
                      {buttonText}
                    </Button>
                  </Space>
                  <StyledLBottomMargin />
                </StyledXlTopMargin>
              </Slide>
            </Col>
          </Row>
        </PageContainer>
      </Layout.Content>
      {windowSize.width <= 992 ? null : (
        <StyledTomaszImg>
          <Img style={{ position: 'initial' }} fluid={desktop.childImageSharp.fluid} />
        </StyledTomaszImg>
      )}
    </>
  );
};

export default AboutMe;
