import { FC } from 'react';

import ReactTicker from 'react-ticker';

interface TickerProps {
  children: JSX.Element;
}

const Ticker: FC<TickerProps> = ({ children }) => {
  return <ReactTicker speed={3}>{() => children}</ReactTicker>;
};

export default Ticker;
