import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const SyledNavigation = styled(motion.ul)`
  padding: 25px;
  position: absolute;
  top: 100px;
  /* width: 100%; */
  margin: 0;
  text-align: center;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 15px;
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;
