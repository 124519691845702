import { FC, useRef } from 'react';

import { Layout } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { Fade } from 'react-awesome-reveal';

import { offers } from '../../data/offers';
import useWindowSize from '../../hooks/useWindowSize';
import { Section } from '../../models/Section';
import Divider from '../Divider/Divider';
import OfferItem from '../OfferItem/OfferItem';
import PageContainer from '../PageContainer/PageContainer';
import { StyledCenterText, StyledXlTopBottomMargin } from '../SharedStyles';
import TitleWithLine from '../TitleWithLine/TitleWithLine';
import {
  StyledCarousel,
  StyledCarouselContainer,
  StyledLeftIcon,
  StyledOffersContainer,
  StyledRightIcon
} from './Offers.styles';

const Offers: FC = () => {
  const carouselRef = useRef<CarouselRef>(null);
  const windowSize = useWindowSize();
  const offersTitle = 'Oferuję wsparcie podczas:'.toUpperCase();

  const offersBlock1 = offers.filter((_, index) => index <= 2);
  const offersBlock2 = offers.filter((_, index) => index > 2);

  const renderCarousel = (): JSX.Element => {
    if (windowSize.width <= 992) {
      return (
        <StyledCarouselContainer>
          <StyledLeftIcon onClick={() => carouselRef.current?.prev()} />
          <StyledCarousel ref={carouselRef}>
            {offers.map((offer) => (
              <StyledOffersContainer key={offer.title}>
                <OfferItem Icon={offer.Icon} title={offer.title} description={offer.description} />{' '}
              </StyledOffersContainer>
            ))}
          </StyledCarousel>
          <StyledRightIcon onClick={() => carouselRef.current?.next()} />
        </StyledCarouselContainer>
      );
    }

    return (
      <StyledCarouselContainer>
        <StyledLeftIcon onClick={() => carouselRef.current?.prev()} />
        <StyledCarousel ref={carouselRef}>
          <StyledOffersContainer>
            {offersBlock1.map((offer) => (
              <OfferItem key={offer.title} Icon={offer.Icon} title={offer.title} description={offer.description} />
            ))}
          </StyledOffersContainer>
          <StyledOffersContainer>
            {offersBlock2.map((offer) => (
              <OfferItem key={offer.title} Icon={offer.Icon} title={offer.title} description={offer.description} />
            ))}
          </StyledOffersContainer>
        </StyledCarousel>
        <StyledRightIcon onClick={() => carouselRef.current?.next()} />
      </StyledCarouselContainer>
    );
  };

  return (
    <Layout.Content id={Section.OFFERS}>
      <PageContainer>
        <StyledXlTopBottomMargin>
          <Fade>
            <StyledCenterText>
              <TitleWithLine rightLine={false}>{offersTitle}</TitleWithLine>
            </StyledCenterText>
            <Divider type="horizontal" variant="medium" />
            {renderCarousel()}
          </Fade>
        </StyledXlTopBottomMargin>
      </PageContainer>
    </Layout.Content>
  );
};

export default Offers;
