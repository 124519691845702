import { FC, useState } from 'react';

import { CgDetailsMore } from '@react-icons/all-files/cg/CgDetailsMore';
import { Menu } from 'antd';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { menuItems } from '../../data/menuItems';
import { Section } from '../../models/Section';
import { StyledMenu } from './NavBar.styles';

const NavBar: FC = () => {
  const [active, setActive] = useState<Section>(Section.ABOUT_ME);

  const handleMenuItemClick = (sectionId: Section): void => {
    scrollTo(`#${sectionId}`);
    setActive(sectionId);
  };

  return (
    <StyledMenu selectedKeys={[active]} overflowedIndicator={<CgDetailsMore size={20} />} mode="horizontal">
      {menuItems.map((menuItem) => (
        <Menu.Item key={menuItem.sectionId} onClick={() => handleMenuItemClick(menuItem.sectionId)}>
          {menuItem.text.toUpperCase()}
        </Menu.Item>
      ))}
    </StyledMenu>
  );
};

export default NavBar;
