import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MdChevronLeft } from '@react-icons/all-files/md/MdChevronLeft';
import { MdChevronRight } from '@react-icons/all-files/md/MdChevronRight';
import { Carousel } from 'antd';

import { colors, mediaMaxWidth } from '../../theme';

export const StyledCarousel = styled(Carousel)`
  &&& {
    & .slick-dots {
      &.slick-dots-bottom {
        bottom: -20px;
      }
      li {
        width: 20px;

        &.slick-active {
          width: 30px;
          button {
            background: ${colors.primary.orange};
          }
        }
      }
    }
  }
`;

export const StyledOffersContainer = styled.div`
  display: flex !important;
  justify-content: center;
`;

const iconsStyles = css`
  position: absolute;
  top: 50%;
  color: ${colors.primary.orange};
  font-size: 40px;
  cursor: pointer;
  z-index: 1;
`;

export const StyledLeftIcon = styled(MdChevronLeft)`
  ${iconsStyles}
  left: 0;

  ${mediaMaxWidth('md')} {
    left: -15px;
  }
`;

export const StyledRightIcon = styled(MdChevronRight)`
  ${iconsStyles}
  right: 0;

  ${mediaMaxWidth('md')} {
    right: -15px;
  }
`;

export const StyledCarouselContainer = styled.div`
  position: relative;
`;
