import { FC } from 'react';

import { IconType } from '@react-icons/all-files/lib/esm';

import BodyText from '../BodyText/BodyText';
import { StyledOfferItemIconContainer, SyledOfferItem, SyledOfferItemTitle } from './OfferItem.styles';

interface OfferItemProps {
  title: string;
  Icon: IconType;
  description: string;
}

const OfferItem: FC<OfferItemProps> = ({ title, description, Icon }) => {
  return (
    <SyledOfferItem>
      <StyledOfferItemIconContainer>
        <Icon />
      </StyledOfferItemIconContainer>
      <SyledOfferItemTitle>{title.toUpperCase()}</SyledOfferItemTitle>
      <BodyText>{description}</BodyText>
    </SyledOfferItem>
  );
};

export default OfferItem;
