import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@sotware-inc/react-components';

import { mediaMaxWidth } from '../../theme';

type Variant = 'left' | 'right';
export interface StyledHalfOverlayProps {
  variant: Variant;
}

const buildHalfOverlayStyles = (variant: Variant): SerializedStyles => {
  if (variant === 'left') {
    return css`
      right: 50%;
      left: 0;
    `;
  }

  return css`
    right: 0;
    left: 50%;
  `;
};

export const StyledHalfOverlay = styled.div<StyledHalfOverlayProps>`
  position: absolute;
  opacity: 0.7;
  bottom: 0;
  top: 0;
  background-color: ${colors.primary.black};
  ${({ variant }) => buildHalfOverlayStyles(variant)}

  ${mediaMaxWidth('md')} {
    right: 0;
    left: 0;
  }
`;
