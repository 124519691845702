import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import { colors, mediaMaxWidth } from '../../theme';

export interface StyledFullBackgroundProps {
  fullViewPort?: boolean;
}

export const buildFullBackgroundStyles = (styledProps: StyledFullBackgroundProps): SerializedStyles => {
  return css`
    width: 100%;
    height: ${styledProps.fullViewPort === true ? '100vh' : 'auto'};
    display: flex;
    justify-content: center;
    overflow: hidden;

    ${mediaMaxWidth('md')} {
      height: auto;
    }
  `;
};

export const StyledFullOverlay = styled.div`
  position: absolute;
  opacity: 0.7;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${colors.primary.black};
`;
