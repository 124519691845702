import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu } from 'antd';

import 'antd/lib/menu/style/index.css';
import { colors } from '../../theme';

const noBottomBorder = css`
  border-bottom: none;
`;

export const StyledMenu = styled(Menu)`
  &&& {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background-color: transparent;

    &,
    .ant-menu-item,
    .ant-menu-item-active,
    .ant-menu-item-selected {
      ${noBottomBorder}
    }

    .ant-menu-item {
      color: ${colors.primary.white};
      transition: all 0.3s ease-in-out;
    }

    .ant-menu-item-active {
      transition: all 0.3s ease-in-out;
      color: ${colors.primary.orange};
    }

    .ant-menu-item-selected {
      color: ${colors.primary.orange};
    }
  }
`;
