import { FC, SVGAttributes } from 'react';

import { motion, MotionProps } from 'framer-motion';

import { colors } from '../../../theme';
import { StyledToggleButton } from './MobileMenuToogle.styles';

interface PathMotionProps
  extends Omit<SVGAttributes<MotionProps>, 'onAnimationStart' | 'onDrag' | 'onDragEnd' | 'onDragStart' | 'style'>,
    MotionProps {}

const Path: FC<PathMotionProps> = ({ variants, d, transition }) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke={colors.primary.orange}
    strokeLinecap="round"
    variants={variants}
    d={d}
    transition={transition}
  />
);

interface MobileMenuToggleProps {
  onToggle: () => void;
}

const MobileMenuToggle: FC<MobileMenuToggleProps> = ({ onToggle }) => (
  <StyledToggleButton aria-label="Toggle menu" onClick={onToggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' }
        }}
      />
    </svg>
  </StyledToggleButton>
);

export default MobileMenuToggle;
