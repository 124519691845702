import { FC } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import AboutMe from '../components/AboutMe/AboutMe';
import BackTop from '../components/BackTop/BackTop';
import Benefits from '../components/Benefits/Benefits';
import ContactMe from '../components/ContactMe/ContactMe';
import Experience from '../components/Experience/Experience';
import Footer from '../components/Footer/Footer';
import FullBackground from '../components/FullBackground/FullBackground';
import HalfScreenOverlay from '../components/HalfScreenOverlay/HalfScreenOverlay';
import Header from '../components/Header/Header';
import LadiesGentlemen from '../components/LadiesGentlemen/LadiesGentlemen';
import Offers from '../components/Offers/Offers';
import SEO from '../components/SEO/SEO';
import Layout from '../templates/Layout/Layout';

const IndexPage: FC = () => {
  const { aboutMe, panstwo, benefits, experience } = useStaticQuery(
    graphql`
      query {
        aboutMe: file(relativePath: { eq: "header.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        panstwo: file(relativePath: { eq: "panstwo.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        benefits: file(relativePath: { eq: "benefits.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        experience: file(relativePath: { eq: "experience.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  return (
    <Layout>
      <SEO />
      <Header />
      <FullBackground fluid={aboutMe.childImageSharp.fluid} fullViewPort>
        <AboutMe />
      </FullBackground>
      <FullBackground fluid={panstwo.childImageSharp.fluid}>
        <HalfScreenOverlay variant="left" />
        <LadiesGentlemen />
      </FullBackground>
      <FullBackground fluid={benefits.childImageSharp.fluid}>
        <HalfScreenOverlay variant="right" />
        <Benefits />
      </FullBackground>
      <Offers />
      <FullBackground fluid={experience.childImageSharp.fluid}>
        <Experience />
      </FullBackground>
      <ContactMe />
      <Footer />
      <BackTop />
    </Layout>
  );
};

export default IndexPage;
