import styled from '@emotion/styled';
import { Typography } from 'antd';

import { colors, mediaMaxHeight, mediaMaxWidth } from '../../theme';

export const StyledTomaszImg = styled.div`
  position: absolute !important;
  max-width: 460px;
  max-height: 720px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;

  ${mediaMaxHeight('md')} {
    max-width: 350px;
    max-height: 660px;
  }

  ${mediaMaxWidth('lg')} {
    max-width: 350px;
  }

  ${mediaMaxWidth('md')} {
    padding-top: 10px;
    padding-bottom: 10px;

    max-height: 700px;
    position: relative !important;
  }
`;

export const StyledTitle = styled(Typography.Title)`
  margin-top: 80px;
  &&& {
    text-align: center;
    color: ${colors.primary.white};
    font-size: 30px;
    font-weight: 300;

    ${mediaMaxWidth('md')} {
      font-size: 23px;
    }
  }
`;

export const StyledHugeTitle = styled(Typography.Title)`
  &&& {
    text-align: right;
    color: ${colors.primary.white};
    white-space: nowrap;
    font-size: 66px;
    font-weight: 600;
    text-shadow: 5px -3px 7px rgba(0, 0, 0, 0.6);

    ${mediaMaxWidth('md')} {
      margin-top: 30px;
      font-size: 50px;
      text-align: center;
    }
  }
`;
