import { FC } from 'react';

import { Variants } from 'framer-motion';

import { menuItems } from '../../../data/menuItems';
import MobileMenuItem from '../MobileMenuItem/MobileMenuItem';
import { SyledNavigation } from './MobileMenuNavigation.styles';

const variants: Variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

interface MobileMenuNavigationProps {
  onToggleClick: () => void;
}

const MobileMenuNavigation: FC<MobileMenuNavigationProps> = ({ onToggleClick }) => (
  <SyledNavigation variants={variants}>
    {menuItems.map((menuItem) => (
      <MobileMenuItem
        key={menuItem.sectionId}
        text={menuItem.text}
        sectionId={menuItem.sectionId}
        onToggleClick={onToggleClick}
      />
    ))}
  </SyledNavigation>
);

export default MobileMenuNavigation;
