import { FC } from 'react';

import { isMobile } from 'react-device-detect';

import MobileMenu from '../MobileMenu/MobileMenu';
import NavBar from '../NavBar/NavBar';
import { StyledHeader } from './Header.styles';

const Header: FC = () => {
  return <StyledHeader>{isMobile ? <MobileMenu /> : <NavBar />}</StyledHeader>;
};

export default Header;
