import { FC } from 'react';

import { Layout } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Section } from '../../models/Section';
import PageContainer from '../PageContainer/PageContainer';
import { StyledCenterText, StyledMTopBottomMargin, StyledOrangeColor } from '../SharedStyles';
import Ticker from '../Ticker/Ticker';
import TitleWithLine from '../TitleWithLine/TitleWithLine';
import { StyledCompanyImgContainer, StyledTickerContainer, StyledTickerImgsContainer } from './Experience.styles';

const query = graphql`
  query Experience {
    ogilvy: file(relativePath: { eq: "companies/Ogilvy.png" }) {
      childImageSharp {
        fixed(width: 130, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rockwool: file(relativePath: { eq: "companies/ROCKWOOL.png" }) {
      childImageSharp {
        fixed(width: 250, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    extor: file(relativePath: { eq: "companies/extor.png" }) {
      childImageSharp {
        fixed(width: 160, height: 45) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    wp: file(relativePath: { eq: "companies/wp.png" }) {
      childImageSharp {
        fixed(width: 80, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    aegon: file(relativePath: { eq: "companies/aegon.png" }) {
      childImageSharp {
        fixed(width: 149, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bionorica: file(relativePath: { eq: "companies/bionorica.png" }) {
      childImageSharp {
        fixed(width: 180, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    brother: file(relativePath: { eq: "companies/brother.png" }) {
      childImageSharp {
        fixed(width: 145, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    consafeLogistics: file(relativePath: { eq: "companies/Consafe_Logistics.png" }) {
      childImageSharp {
        fixed(width: 273, height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cslBehring: file(relativePath: { eq: "companies/CSL-Behring.png" }) {
      childImageSharp {
        fixed(width: 217, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hapagLloyd: file(relativePath: { eq: "companies/Hapag-Lloyd.png" }) {
      childImageSharp {
        fixed(width: 262, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ks: file(relativePath: { eq: "companies/ks-logo-main.png" }) {
      childImageSharp {
        fixed(width: 289, height: 35) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mellon: file(relativePath: { eq: "companies/mellon.png" }) {
      childImageSharp {
        fixed(width: 174, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    milkiland: file(relativePath: { eq: "companies/milkiland.png" }) {
      childImageSharp {
        fixed(width: 174, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    monsterPolska: file(relativePath: { eq: "companies/monster-polska.png" }) {
      childImageSharp {
        fixed(width: 274, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    saski: file(relativePath: { eq: "companies/Saski.png" }) {
      childImageSharp {
        fixed(width: 296, height: 35) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sumitomo: file(relativePath: { eq: "companies/sumitomo.png" }) {
      childImageSharp {
        fixed(width: 278, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    wu: file(relativePath: { eq: "companies/wu.png" }) {
      childImageSharp {
        fixed(width: 258, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Experience: FC = () => {
  const {
    ogilvy,
    rockwool,
    extor,
    wp,
    aegon,
    bionorica,
    brother,
    consafeLogistics,
    cslBehring,
    hapagLloyd,
    ks,
    mellon,
    milkiland,
    monsterPolska,
    saski,
    sumitomo,
    wu
  } = useStaticQuery(query);

  const companyLogos = [
    ogilvy,
    rockwool,
    extor,
    wp,
    aegon,
    bionorica,
    brother,
    consafeLogistics,
    cslBehring,
    hapagLloyd,
    ks,
    mellon,
    milkiland,
    monsterPolska,
    saski,
    sumitomo,
    wu
  ];

  return (
    <Layout.Content id={Section.EXPERIENCE}>
      <PageContainer>
        <StyledMTopBottomMargin>
          <StyledCenterText>
            <TitleWithLine rightLine={false}>
              <StyledOrangeColor>15</StyledOrangeColor>
              {' lat doświadczenia'.toUpperCase()}
            </TitleWithLine>
            <StyledTickerContainer>
              <Ticker>
                <StyledTickerImgsContainer>
                  {companyLogos.map((logo) => (
                    <StyledCompanyImgContainer key={logo.childImageSharp.fixed.base64}>
                      <Img fixed={logo.childImageSharp.fixed} />
                    </StyledCompanyImgContainer>
                  ))}
                </StyledTickerImgsContainer>
              </Ticker>
            </StyledTickerContainer>
          </StyledCenterText>
        </StyledMTopBottomMargin>
      </PageContainer>
    </Layout.Content>
  );
};

export default Experience;
